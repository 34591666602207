<template>
  <div>
    <el-upload
      class="image-uploader"
      action="/"
      :show-file-list="false"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      :limit="limit"
      :http-request="handleUpload"
      ref="upload"
    >
      <div class="image-div" v-for="(item, index) in imageUrlList" :key="index" v-show="showFileList">
        <span class="el-icon-error" @click="deleteImg(index)"></span>
        <img :src="item" class="image">
      </div>
      <div slot="trigger">
        <slot>
          <div class="image-uploader-div flex flex-column flex-center" v-show="imageUrlList.length < limit">
            <i class="image-uploader-icon"></i>
            <div>
              点击上传
            </div>
          </div>
        </slot>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { upload_file } from '@/api/common'
export default {
  props: {
    limit: {
      type: Number,
      default: 1
    },
    urls: {
      type: Array,
      default: () => {
        return []
      }
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    accept:{
      type:Array,
      default:()=>['image/jpeg','image/png','image/gif']
    },
    message:{
      type:String,
      default:'上传图片只能是 JPG、PNG、GIF 格式!'
    }
  },
  watch: {
    urls(val) {
      this.imageUrlList = val.concat()
    }
  },
  data() {
    return {
      imageUrlList: [],
      file: null,
      fileList: []
    };
  },
  methods: {
    handleSuccess(res, file) {
      console.log(res, file)
    },
    beforeUpload(file) {
      console.log(file)
      this.file = file
      const isJPG = this.accept.includes(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.warning(this.message);
      }
      if (!isLt10M) {
        this.$message.warning('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    onChange(file, fileList) {
      console.log(file, fileList)
      this.fileList = fileList
      this.file = file
      this.handleUpload()
    },
    handleUpload() {
      if (this.imageUrlList.length >= this.limit && this.limit != 1) {
        this.$message.warning(`图片最多上传${this.limit}张`);
        return
      }
      let params = new FormData()
      params.append('file', this.file)
      this.$emit('loading', true)
      upload_file(params).then(res => {
        console.log(res)
        if (this.limit == 1) {
          this.imageUrlList = [res]
        } else {
          this.imageUrlList.push(res)
        }
        this.$emit('change', this.imageUrlList)
      })
    },
    deleteImg(index) {
      this.imageUrlList.splice(index, 1)
      this.$emit('change', this.imageUrlList)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-div{
  border: 1px solid #d9d9d9;
  position: relative;
  float: left;
  margin-right: 10px;
  .el-icon-error{
    color: #8E2525;
    font-size: 12px;
    position: absolute;
    right: -6px;
    top: -6px;
    cursor: pointer;
  }
}
.image-uploader-div{
  border: 1px solid #d9d9d9;
  font-size: 12px;
  color: #A4A4A4;
  width: 72px;
  height: 72px;
}
.image-uploader-icon {
  width: 28px;
  height: 22px;
  background: url('../assets/icons/icon-upload.png');
  display: block;
  margin: 0 auto 8px;
}
.image {
  width: 72px;
  height: 72px;
  display: block;
}
</style>