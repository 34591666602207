<template>
  <el-image-viewer
    v-if="visible"
    :z-index="zIndex"
    :initial-index="initialIndex"
    :on-close="onClose"
    :on-switch="onSwitch"
    :url-list="urlList"
  />
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    urlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    onSwitch: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    initialIndex: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style>

</style>