<template>
  <div class="page-content">
    <div class="page-sub-title">
      开票信息
    </div>
    <div class="invoice-content flex flex-wrap">
      <div>
        <span>
          开票金额（元）：
        </span>
        <span>
          {{$commaNum(form.invoiceAmount)}}
        </span>
      </div>
      <div>
        <span>
          发票类型：
        </span>
        <span>
          {{invoiceTypeMap[form.invoiceType]}}
        </span>
      </div>
      <div>
        <span>
          开票单位全称：
        </span>
        <span>
          {{form.invoiceCompany}}
        </span>
      </div>
      <div>
        <span>
          纳税人识别号：
        </span>
        <span>
          {{form.taxpayerCode}}
        </span>
      </div>
      <div v-show="form.invoiceType == 2">
        <span>
          单位地址：
        </span>
        <span>
          {{form.invoiceCompanyAddr}}
        </span>
      </div>
      <div v-show="form.invoiceType == 2">
        <span>
          单位电话：
        </span>
        <span>
          {{form.invoiceCompanyPhone}}
        </span>
      </div>
      <div v-show="form.invoiceType == 2">
        <span>
          开户银行：
        </span>
        <span>
          {{form.invoiceBank}}
        </span>
      </div>
      <div v-show="form.invoiceType == 2">
        <span>
          开户账号：
        </span>
        <span>
          {{form.invoiceBankAccount}}
        </span>
      </div>
      <div class="flex flex-align-center">
        <span>
          申请时间：
        </span>
        <span>
          {{form.createTime}}
        </span>
      </div>
      <div class="flex flex-align-center">
        <span>
          开票时间：
        </span>
        <span>
          {{form.updateTime}}
        </span>
      </div>
      <div class="flex flex-align-center">
        <span>
          开票状态：
        </span>
        <span class="mr-10">
          {{invoiceStatusMap[form.invoiceStatus]}}
        </span>
        <span>
          <el-button type="primary" size="mini" style="width: 100px" v-if="form.invoiceStatus == 2" @click="$refs.upload.$el.querySelector('input[type=file]').click()
          ">
          <div class="flex flex-align-center">
            <img style="width:16px;height:16px" src="../../assets/icons/upload.png" alt="">
            <span class="ml-5">上传发票</span>
          </div>
          </el-button>
        </span>
      </div>
      <div class="prove flex flex-align-center">
        <span class="flex-shrink">
          一般纳税人证明：
        </span>
          <span class="flex flex-align-center" v-if="form.taxpayerCertificateName && form.taxpayerCertificate">
            <span class="mr-10 flex-1">{{ form.taxpayerCertificateName | formatterAppendixName}}</span>
            <!-- <span class="ellipsis flex-shrink" style="max-width:240px">{{fileName.nosuffix}}</span>
            <span class="flex-shrink mr-10">{{fileName.suffixWord + '.' + fileName.suffix}}</span> -->
            <img src="../../assets/icons/browse.png" class="mr-10" alt="" style="cursor:pointer" @click="previewOrDownload(form.taxpayerCertificate,1)" />
            <img src="../../assets/icons/download.png" alt="" @click="previewOrDownload(form.taxpayerCertificate,2)" style="cursor:pointer" />
          </span>
      </div>
    </div>
    <div class="page-sub-title">
      收件信息
    </div>
    <div class="invoice-content flex flex-wrap">
      <div>
        <span>
          收件方式：
        </span>
        <span>
          {{receiverTypeMap[form.receiverType]}}
        </span>
      </div>
      <!-- <div>
        <span>
          收件人：
        </span>
        <span>
          {{form.receiver}}
        </span>
      </div> -->
      <!-- <div>
        <span>
          收件人电话：
        </span>
        <span>
          {{form.receiverPhone}}
        </span>
      </div> -->
      <div v-show="form.receiverType == 1">
        <span>
          邮箱地址：
        </span>
        <span>
          {{form.receiverEmail}}
        </span>
      </div>
      <div v-show="form.receiverType == 2">
        <span>
          收件地址：
        </span>
        <span>
          {{form.receiverAddr}}
        </span>
      </div>
      <div v-show="form.receiverType == 2">
        <span>
          邮编：
        </span>
        <span>
          {{form.receiverPostalCode}}
        </span>
      </div>
      <div>
        <span>
          备注：
        </span>
        <span>
          {{form.remark}}
        </span>
      </div>
    </div>
    <div class="page-sub-title">
      充值记录
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      header-row-class-name="table-header"
    >
      <el-table-column
        type="index"
        align="center"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderNum"
        label="充值编号"
      ></el-table-column>
      <el-table-column
        align="center"
        label="申请开票类型"
      >
        <template slot-scope="scope">
          {{invoiceMap[scope.row.applyInvoiceType]}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="smsTypeName"
        label="充值短信套餐"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="amount"
        label="支付金额（元）"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="payTime"
        label="支付时间"
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      class="text-right m-t-16"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="pageSizes"
      :current-page="pageParams.pageNo"
      :page-size="pageParams.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems">
    </el-pagination>

    <div class="text-center m-t-16">
      <!-- <el-button type="primary" size="small" @click="goback" style="width: 128px">返回</el-button> -->
      <el-button type="default" size="small" style="width: 130px" @click="goback">返回</el-button>
    </div>
    <image-viewer :visible="imageVisible" :urlList="urlList" :on-close="closeViewer"></image-viewer>
    <upload ref="upload" class="upload" :showFileList="false" :accept="['image/jpeg','image/png','application/pdf']" :message="'上传文件只能是 JPG、PNG、PDF 格式!'" @change="uploadChange"></upload>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_invoice_detail, invoiceUpload} from '@/api/finance'
import imageViewer from '@/components/image-viewer'
import upload from '@/components/upload'
import { getFileType } from '@/utils/index'

export default {
  name: 'finance-invoice-detail',
  mixins: [pageMixin],
  components:{
    imageViewer,
    upload
  },
  data() {
    return {
      invoiceRecordId: '',
      invoiceMap: {},
      invoiceTypeMap: {},
      receiverTypeMap: {},
      invoiceStatusMap: {},
      form: {
        invoiceAmount: '',
        invoiceType: 1, //发票类型 1增值税普通发票, 2增值税专用发票
        invoiceCompany: '',
        taxpayerCode: '',
        invoiceCompanyAddr: '',
        invoiceCompanyPhone: '',
        invoiceBank: '',
        invoiceBankAccount: '',
        receiverType: 1, //邮寄类型 1电子发票 2邮寄发票
        receiver: '',
        receiverPhone: '',
        receiverEmail: '',
        receiverAddr: '',
        receiverPostalCode: '',
        remark: ''
      },
      imageVisible:false,
      urlList:[],
      fileName:{}
    }
  },
  filters:{
    formatterAppendixName(val){
      if(!val) return
      if(val.length <= 20) return val
      let extension  = val.split('.').pop()
      let fileNameWithoutExtension = val.slice(0, -(extension.length + 1)); // 去除后缀的文件名
      let abbreviatedName = fileNameWithoutExtension.slice(0, 11) + '...' + fileNameWithoutExtension.slice(-1); // 在最后一个字之间插入省略号
      return abbreviatedName + '.' + extension; // 返回带有省略号的缩写文件名
    }
  },
  watch:{
    'form.taxpayerCertificateName'(val){
      console.log(val);
      let splitName = val.split('.')
      this.fileName.nosuffix = splitName[0].slice(0,-1)
      this.fileName.suffixWord = splitName[0].slice(-1)
      this.fileName.suffix = splitName[1]
    }
  },
  mounted() {
    this.form = JSON.parse(this.$route.query.invoiceDetail)
    this.invoiceRecordId = this.form.id
    this.invoiceMap = JSON.parse(this.$route.query.invoiceMap)
    this.invoiceTypeMap = JSON.parse(this.$route.query.invoiceTypeMap)
    this.receiverTypeMap = JSON.parse(this.$route.query.receiverTypeMap)
    this.invoiceStatusMap = JSON.parse(this.$route.query.invoiceStatusMap)
    this.getList()
  },
  methods: {
    previewOrDownload(val,num){
      if(!val) return
      let type = getFileType(val)
      if(num === 2){
        window.open(val, '_blank') // 新标签页
      }else{
        if(['png','jpg'].includes(type)){
          this.imageVisible = true;
          this.urlList  = [val]
        }else{
          window.open(val, '_blank') // 新标签页
        }
      }
    },
    uploadChange(val){
      console.log(val[0]);
      invoiceUpload({
        invoiceRecordId:this.invoiceRecordId,
        voucher:val[0]
      }).then((res)=>{
        this.$refs.upload.$refs.upload.clearFiles()
        console.log(res);
        this.$message.success('上传发票成功')
      })
    },
    closeViewer(){
      this.imageVisible = false;
      this.urlList = []
    },
    goback() {
      const keepAlive = this.$store.state.page.keepAlive.concat(['finance-invoice'])
      this.$store.commit('page/SET_KEEPALIVE', keepAlive)
      this.$router.go(-1)
    },
    getList() {
      get_invoice_detail({
        invoiceRecordId: this.invoiceRecordId,
        ...this.pageParams
      }).then(res => {
        this.list = res ? res.records : []
        this.totalItems = res ? res.total : 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-content{
  padding-left: 10px;
  & > div{
    width: 24%;
    margin-right: 1%;
    margin-bottom: 16px;
  }
}
.upload {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
}
.prove{
  img{
    width: 16px;
    height: 16px;
  }
}
</style>