import { ajax } from "@/api/request";

// 开票申请列表
export const get_invoice_list = function (data) {
  return ajax({
    method: 'post',
    url: '/invoice/v1/applyInvoiceList',
    data
  });
}

// 开票
export const confirm_invoice = function (data) {
  return ajax({
    method: 'post',
    url: '/invoice/v1/invoiceBatch',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 开票详情
export const get_invoice_detail = function (data) {
  return ajax({
    method: 'post',
    url: '/invoice/v1/invoiceDetail',
    data
  });
}
// 短信充值记录
export const get_recharge_record = function (data) {
  return ajax({
    method: 'post',
    url: '/invoice/v1/smsOrderList',
    data
  })
}
// 导出短信充值记录
export const download_recharge_record = function (orderNumList,hotelName,invoiceStatus,type,startTime,endTime,orderNum) {
  return ajax({
    method: 'get',
    url: '/invoice/v1/downloadSmsOrderListExcel',
    params: {
      orderNumList:orderNumList,
      hotelName:hotelName,
      invoiceStatus:invoiceStatus,
      type:type,
      startTime:startTime,
      endTime:endTime,
      orderNum:orderNum
    }
  })
}

// 上传发票
export const invoiceUpload = function (data) {
  return ajax({
    method: 'post',
    url: 'invoice/v1/voucherUpload',
    data
  })
}