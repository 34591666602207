export const pageMixin = {
  data() {
    return {
      list: [],
      init: false,
      loading: false,
      addStatus: false,
      dialogVisible: false,
      totalItems: 0,
      pageSizes: [10,20,50,100,200],
      pageParams: {
        pageNo: 1,
        size: 20
      },
      selected: [],
      originSearchParams: {},
      searchParams: {}
    }
  },
  created() {
    this.originSearchParams = this.$deepClone(this.searchParams)
  },
  methods: {
    renderForm(form, data) {
      for (const key in form) {
        form[key] = data[key]
      }
    },
    search() {
      this.pageParams.pageNo = 1
      this.getList()
    },
    reset() {
      this.searchParams = this.$deepClone(this.originSearchParams)
      this.pageParams = {
        pageNo: 1,
        size: 20
      }
      this.getList()
    },
    handleSelectionChange(e) {
      this.selected = e
    },
    handleSizeChange(e) {
      this.pageParams.pageNo = 1
      this.pageParams.size = e
      this.getList()
    },
    handleCurrentChange(e) {
      this.pageParams.pageNo = e
      this.getList()
    }
  }
}
